import { useState, useEffect, useCallback } from 'react'
import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { useDetailsContext } from './DetailsContext'
import { scrollUtil } from '@/utils'
import { InView } from '../../common'
import { useRouter } from 'next/router'
import useMeasure from '@/services/hooks/useMeasure'
import useCampaignShare from '@/services/hooks/useCampaignShare'
import SegmentHandler from '@/services/analytics/SegmentHandler'

export default function DetailsTabs({ campaign }) {
  const { tabs, selectedTab, setTab } = useDetailsContext()
  const [tabsOverflowing, setTabsOverflowing] = useState(false)
  const [viewedSections, setViewedSections] = useState([])
  const router = useRouter()
  const [isStuck, setStuck] = useState(router.asPath.split('#').length === 1)

  // Handle selecting a tab
  const handleChange = useCallback(
    (idx) => {
      const el = document.getElementById(tabs[idx].id)
      const margin = idx === 0 ? 80 : 0
      scrollUtil.scrollToEl(el, margin)
      // Need a small timeout to override auto-setting of tabs
      setTimeout(() => {
        setTab(idx)
      }, 100)
    },
    [tabs, setTab]
  )

  const handleClick = useCallback(
    (idx) => {
      SegmentHandler.track('On Tap', {
        project: campaign.slug,
        location: router.asPath,
        section_name: 'body',
        element_name: 'menu_item',
        value: tabs[idx].title,
        action_type: 'tap',
      })
      handleChange(idx)
    },
    [handleChange, tabs, campaign, router.asPath]
  )

  // Set overflow state when the container is resized
  const [containerRef, containerDimensions] = useMeasure()
  useEffect(() => {
    // TODO: eventually Headless UI should allows us to get this using a forwarded ref
    const tabsEl = document.querySelector('.tab-container > div')
    setTabsOverflowing(
      tabsEl ? tabsEl.scrollWidth > containerDimensions.width : false
    )
  }, [containerDimensions.width])

  // Scroll selected tab into view if it's offscreen
  useEffect(() => {
    // TODO: eventually Headless UI should allows us to get this using a forwarded ref
    const tabEl = document.querySelector('.tab-container button.border-oxide')
    if (!tabEl || window.scrollY === 0) return // abort if no selected tab or user hasn't started scrolling
    const { left, right } = containerDimensions
    const { left: tabLeft, right: tabRight } = tabEl.getBoundingClientRect()

    if (tabLeft < left || tabRight > right) {
      tabEl.scrollIntoView()
    }
  }, [selectedTab, containerDimensions.width, containerDimensions])

  useEffect(() => {
    const section = tabs[selectedTab]?.title
    if (!section || viewedSections.includes(section)) return

    setViewedSections((cur) => [...cur, section])

    SegmentHandler.track('Section Viewed', {
      project: campaign.slug,
      location: router.asPath,
      section_name: 'body',
      value: section,
      action_type: 'scroll',
    })
  }, [selectedTab, tabs, campaign, router.asPath, viewedSections])

  const shareItems = useCampaignShare({ campaign })

  return (
    <>
      <InView onEnter={() => setStuck(false)} onExit={() => setStuck(true)}>
        <div style={{ height: 1 }} />
      </InView>
      <div
        ref={containerRef}
        className={classNames({
          'hide-scrollbar tab-container sticky top-0 bg-white z-50 pt-4': true,
          'flex justify-center': !tabsOverflowing,
          'overflow-x-auto': tabsOverflowing,
        })}
        style={{
          boxShadow: isStuck ? '1px 1px 5px rgba(0,0,0,0.25)' : 'none',
        }}
      >
        <Tab.Group onChange={handleClick} className="whitespace-nowrap">
          <Tab.List>
            {tabs.map(({ title }, idx) => (
              <Tab
                key={idx}
                data-cy="page-nav-tabs"
                className={classNames({
                  'text-sm uppercase border-b-2 py-3 px-4 transition-colors focus:outline-none': true,
                  'text-gray border-transparent': idx !== selectedTab,
                  'text-oxide border-oxide': idx === selectedTab,
                })}
              >
                {title}
              </Tab>
            ))}
          </Tab.List>
        </Tab.Group>

        <div className="hidden lg:flex absolute right-0 top-0 bottom-0 items-center px-8">
          {shareItems.map(({ Icon, href, label }, idx) => (
            <a
              key={idx}
              href={href}
              title={`Share on ${label}`}
              target="_blank"
              rel="noreferrer"
              className={idx === shareItems.length - 1 ? '' : 'mr-2'}
            >
              <Icon width={35} height={35} />
            </a>
          ))}
        </div>
      </div>
    </>
  )
}
