import React from 'react'
import { Text } from '@/components/common'

export interface Props {
  idx: number
  id: string
  title: string
  Component: () => JSX.Element
  itemsRef: { current: (HTMLElement | null)[] }
}

const ComponentWrap: React.FC<Props> = ({
  idx,
  id,
  itemsRef,
  title,
  Component,
}) => {
  return (
    <div
      key={id}
      id={id}
      ref={(el) => (itemsRef.current[idx] = el)}
      className="flex flex-col justify-center pt-20 first:pt-0 first:mt-6"
    >
      <div className="bg-veryLightGray border border-lightGray rounded-sm py-1 mb-6">
        <Text as="h2" preset="heading.md" className="text-center uppercase">
          {title}
        </Text>
      </div>
      <Component />
    </div>
  )
}

export default React.memo(ComponentWrap, () => true)
